<template>
  <div class="phone" @click="showOrganPhone">{{getSecretPhone()}}
  </div>
</template>

<script>
export default {
  name: 'phone',
  props: {
    phone: {
      type: String,
      default: '-'
    },
  },
  data(){
    return {
      status:true
    }
  },
  created() {
  },
  mounted() {

  },
  watch:{
    phone(newValue,oldValue){
      if(oldValue&&oldValue!=newValue){
        this.status = true
      }
    }
  },
  methods:{
    getSecretPhone(){
      if(this.status){
        if(this.phone && this.phone.length == 11){
          let phone = this.phone.substr(0,3)+"******"+this.phone.substr(this.phone.length-5)
          return phone
        }
        if(this.phone && this.phone.length > 11){
          let phone = this.phone.substr(0,6)+"**********"+this.phone.substr(this.phone.length-6)
          return phone
        }
      }
      return this.phone
    },
    showOrganPhone(){
      this.status = !this.status
    }
  },

}
</script>

<style scoped>
  .phone{
    cursor: pointer;
  }
  .phone:hover{
    cursor: pointer;
    color: #1F74FC;
    /*text-decoration:underline*/
  }

  .prompt-box {
    color: #cccccc;
    z-index: 100;
    position: absolute;
    left: 50px;
    top: 25px;
    display: none;
  }

  .phone:hover .prompt-box {
    display: none;
  }
</style>
